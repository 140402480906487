<template>
  <div class="content-wrapper">
    <h1 class="mb-5">Roles</h1>
    <b-card class="mb-4">
      <b-row class="my-1">
        <b-col lg="3">
          <b-input-group size="sm" class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>
          </b-input-group>
        </b-col>

        <b-col class="text-lg-right">
          <b-button size="sm" class="mb-2" v-b-toggle.sidebar-roles>
            <b-icon icon="plus" aria-hidden="true"></b-icon> New Role
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="items == null" rounded="sm" class="overlay-placeholder">
      <b-card>
        <b-table
          hover
          :items="filteredItems"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
        >
          <template #cell(actions)="row">
            <b-dropdown
              size="sm"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <b-icon-gear-fill></b-icon-gear-fill>
              </template>
              <b-dropdown-item
                href="#"
                v-b-toggle.sidebar-roles
                @click="
                  currentRole = row.item;
                  tmpRole = JSON.parse(JSON.stringify(row.item));
                "
                >Edit</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                v-if="row.item.attiva"
                @click="row.item.attiva = !row.item.attiva"
                >Block</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="
                  currentRole = row.item;
                  showDeleteModal = true;
                "
                >Delete</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template #cell(id_padre)="row">
            {{ getRoleName(row.value) }}
          </template>
          <template #cell(id_company)="row">
            {{ getCompanyName(row.value) }}
          </template>
          <template #cell(attiva)="row">
            <b-icon-unlock-fill v-if="row.item.attiva"></b-icon-unlock-fill>
            <b-icon-lock-fill v-else></b-icon-lock-fill>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="mt-4 mb-1"
        ></b-pagination>
      </b-card>
    </b-overlay>
    <b-sidebar
      class="right-sidebar"
      id="sidebar-roles"
      right
      shadow
      lazy
      @hidden="resetData"
    >
      <div class="content-wrapper my-3 mx-1">
        <h4 v-if="tmpRole && tmpRole.nome">
          {{ tmpRole.nome }}
        </h4>
        <h4 v-else>New Role</h4>
        <b-form-group label="Nome" label-for="role">
          <b-form-input v-model="tmpRole.nome" />
        </b-form-group>
        <!-- <b-form-group label="Company">
          <b-form-select
            v-model="tmpRole.id_company"
            :options="companyList"
            @change="tmpRole.id_padre = null"
          ></b-form-select>
        </b-form-group> -->
        <template v-if="tmpRole.id_company">
          <b-row>
            <b-col>
              <b-form-group label="Parent role" label-for="role">
                <b-form-select
                  v-model="tmpRole.id_padre"
                  :options="companyRoles"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Settore" label-for="settore">
                <b-form-input id="settore" v-model="tmpRole.settore" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Codice ISTAT" label-for="codice_istat">
                <b-form-input
                  id="codice_istat"
                  v-model="tmpRole.codice_istat"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Area di Attività (AdA)" label-for="ada">
                <b-form-input id="ada" v-model="tmpRole.ada" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Regione" label-for="regione">
                <b-form-input id="regione" v-model="tmpRole.regione" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Lista di competenze"
                label-for="lista_competenze"
              >
                <small
                  >Usa il tasto CTRL o CMD per selezionare più di una
                  opzione</small
                >
                <small
                  ><b-button
                    @click="tmpRole.competenze = []"
                    size="sm"
                    variant="light"
                    class="float-right mb-2"
                    >Remove all</b-button
                  ></small
                >
                <b-form-select
                  id="lista_competenze"
                  v-model="tmpRole.competenze"
                  :options="computedCompetenze"
                  multiple
                  :select-size="5"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Ruolo" label-for="role">
                <b-form-textarea v-model="tmpRole.ruolo[itemsLang.ruolo]" />
                <LangOptions v-model="itemsLang.ruolo" :options="languages" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="theme == 'fdt-theme'">
            <b-col>
              <b-form-group label="Risultato" label-for="role">
                <b-form-textarea
                  v-model="tmpRole.attivita[itemsLang.attivita]"
                />
                <LangOptions
                  v-model="itemsLang.attivita"
                  :options="languages"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <b-form-group label="Attività" label-for="role">
                <b-form-textarea
                  v-model="tmpRole.attivita[itemsLang.attivita]"
                />
                <LangOptions
                  v-model="itemsLang.attivita"
                  :options="languages"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="theme == 'fdt-theme'">
            <b-col>
              <b-form-group label="Funzioni" label-for="role">
                <b-form-textarea v-model="tmpRole.mission[itemsLang.mission]" />
                <LangOptions v-model="itemsLang.mission" :options="languages" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <b-form-group label="Mission" label-for="role">
                <b-form-textarea v-model="tmpRole.mission[itemsLang.mission]" />
                <LangOptions v-model="itemsLang.mission" :options="languages" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="theme == 'fdt-theme'">
            <b-col>
              <b-form-group label="Descr. Operativa Funzioni" label-for="role">
                <b-form-textarea
                  v-model="tmpRole.resp_compiti[itemsLang.respCompiti]"
                />
                <LangOptions
                  v-model="itemsLang.respCompiti"
                  :options="languages"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <b-form-group label="Resp. Compiti" label-for="role">
                <b-form-textarea
                  v-model="tmpRole.resp_compiti[itemsLang.respCompiti]"
                />
                <LangOptions
                  v-model="itemsLang.respCompiti"
                  :options="languages"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </template>
        <div class="mt-5 mb-5">
          <b-button
            squared
            variant="primary"
            size="lg"
            class="big-button"
            @click="saveItem"
            >Confirm</b-button
          >
        </div>
      </div>
    </b-sidebar>
    <b-modal
      v-model="showDeleteModal"
      title="Attention!"
      ok-title="Confirm"
      cancel-title="Cancel"
      @hidden="resetData"
      @ok="deleteItem(currentRole.id)"
      >Do you really want to delete the selected element?</b-modal
    >
  </div>
</template>

<script>
import AxiosService from "./../axiosServices/AxiosService.js";
import { theme } from "@/config/global";
import { mapGetters } from "vuex";
import { SET_CURRENT_COMPANY } from "../store/actions/config";
export default {
  components: {},
  data() {
    return {
      items: null,
      fields: [
        { key: "id", label: "ID" },
        { key: "nome", label: "Nome" },
        { key: "id_padre", label: "Parent Role" },
        { key: "id_company", label: "Company" },
        { key: "actions", label: "Actions" },
      ],
      view_lang: null,
      itemsLang: {
        attivita: null,
        mission: null,
        respCompiti: null,
        ruolo: null,
      },
      currentPage: 1,
      perPage: 10,
      filter: "",
      currentRole: null,
      tmpRole: {},
      showDeleteModal: false,

      companyList: [],
      companyListSelected: null,

      theme: "",

      competences: [],
      competencesComplete: [],
    };
  },
  service: null,
  created() {
    this.service = new AxiosService("Role");
    this.resetData();
    this.theme = theme;
    console.log(theme);
  },
  async mounted() {
    this.service.read().then((data) => {
      this.items = data;
    });
    await this.service
      .getOptions("Company")
      .then((data) => (this.companyList = data));

    this.companyListSelected = this.currentCompanyFromStore;

    this.getCompetences();
  },
  methods: {
    getCompetences() {
      const service = new AxiosService("Competenza/Get");
      this.competences.splice(0);
      this.competencesComplete.splice(0);
      try {
        service
          .read()
          .then((response) => {
            this.competencesComplete = response;
            this.competences = response.map((item) => {
              return { value: item.id, text: item.nome };
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    },
    resetData() {
      this.currentRole = null;
      this.tmpRole = {
        id: 0,
        attivita: "",
        id_padre: null,
        mission: "",
        nome: "",
        resp_compiti: "",
        ruolo: "",
        id_company: null,
      };
      for (const property in this.itemsLang) {
        this.itemsLang[property] = this.default_lang.value;
      }
    },
    saveItem() {
      let item = { ...this.tmpRole };

      const validationObject = this.$validate("role", item);
      if (!validationObject.validation) {
        this.$errorToast(validationObject.messages.join(", "));
        return false;
      }

      if (item.id) {
        this.service
          .update(item)
          .then((response) => {
            if (response.id == item.id) {
              Object.keys(this.currentRole).forEach((element) => {
                if (Object.prototype.hasOwnProperty.call(item, element)) {
                  this.currentRole[element] = item[element];
                }
              });
              this.$root.$emit("bv::toggle::collapse", "sidebar-roles");
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          });
      } else {
        this.service
          .create(item)
          .then((response) => {
            if (response.id) {
              item.id = response.id;
              this.items.push(item);
              this.$root.$emit("bv::toggle::collapse", "sidebar-roles");
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          });
      }
    },
    deleteItem(id) {
      this.service
        .delete(id)
        .then((response) => {
          if (response) {
            this.items = this.items.filter(
              (currentItem) => id !== currentItem.id
            );
            this.$successToast();
          } else {
            this.$errorToast();
            return false;
          }
        })
        .catch(() => {
          this.$errorToast();
          return false;
        });
    },
    getRoleName(idRole) {
      const role = this.items.find((x) => x.id === idRole);
      return role ? role.nome : "";
    },
    getCompanyName(id) {
      const company = this.companyList.find((x) => x.value === id);
      return company ? company.text : "";
    },
  },
  computed: {
    ...mapGetters(["languages"]),
    ...mapGetters(["default_lang"]),
    computedCompetenze() {
      if (!this.tmpRole.id_company) return this.competences;
      let filteredCompetences = this.competencesComplete.filter(
        (x) => x.idCompany == this.tmpRole.id_company
      );
      return filteredCompetences.map((x) => ({ text: x.nome, value: x.id }));
    },
    totalRows() {
      return this.filteredItems ? this.filteredItems.length : 0;
    },
    filterCompanyList() {
      return [{ value: null, text: "All companies" }, ...this.companyList];
    },
    companyRoles() {
      const company = this.tmpRole.id_company;
      if (company) {
        let roles = this.items
          .filter((x) => x.id_company == company && x.id != this.tmpRole.id)
          .map((x) => ({ text: x.nome, value: x.id }));
        roles.unshift({ text: "No parent", value: null });
        return roles;
      } else {
        return [];
      }
    },
    filteredItems() {
      if (this.items) {
        return this.items.filter((item) => {
          if (
            this.companyListSelected &&
            item.id_company != this.companyListSelected
          ) {
            return false;
          }
          return true;
        });
      } else {
        return [];
      }
    },
    currentCompanyFromStore() {
      return this.$store.getters.currentCompany;
    },
  },
  watch: {
    filter: {
      handler() {
        this.currentPage = 1;
      },
    },
    currentCompanyFromStore() {
      this.companyListSelected = this.currentCompanyFromStore;
    },
    companyListSelected() {
      this.$store.dispatch(SET_CURRENT_COMPANY, this.companyListSelected);
    },
  },
};
</script>
